import { Inject, Injectable, Injector, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { AnimationItem } from "lottie-web";
import { Subject, fromEvent, merge } from "rxjs";
import { InjectionTokens, ModeDevice } from "../constants/application/application-constants";
import { AppPopupConfigModel } from "../interfaces/popup.model";
import { StorageService } from "./storage.service";
@Injectable({ providedIn: "root" })
export class AppCommonService {
  quote: any;
  // subjects
  private viewAllConfig = new Subject<any>();
  private showToastSub = new Subject<any>();
  public deviceRegistrationEvent = new Subject<any>();
  private cartCountSubject = new Subject<any>();
  private wishlistSubscription = new Subject<any>();
  private giftBoxCountSubject = new Subject<any>();
  private eventHandler = new Subject<any>();
  private popupSubscription = new Subject<AppPopupConfigModel>();
  
  cartcount: number = 0;
  public socialLoginData: any;
  public isLoginRequiredPage: boolean = false;
  public loginRedirectUrl: any;
  wishlistcount: number = 0;
  /*** Toast Notification specific Variables ***/
  showToast: boolean = false;
  toastMsg: any;
  timeoutTimer;
  viewAllObj: any;
  tryonUrl: any;
  modeDevice: any = ModeDevice.mobile;
  isWebview:boolean;
  isBot:boolean;
  buildNumber: any;
  itemDefaultImg = "https://media6.ppl-media.com/mediafiles/ecomm/misc/1522316396_default_product_image_100_x_100.png";
  rhDefaultImg = "https://media6.ppl-media.com/mediafiles/ecomm/misc/1522316396_default_product_image_50_x_50.png";
  bannerDefaultImg = "https://media6.ppl-media.com/mediafiles/ecomm/misc/1522221181_dot2x1.png";
  updateHorizontalScroll: any;
  scrollObservable: any;
  checkoutStatusSrc: any;
  freebiePop: boolean = true;
  menuLoaded: boolean = false;
  superMenuLoaded: boolean = false;
  chatbotInitialized: boolean = false;
  showBottomNavBar: boolean = false;
  darkhThem: Boolean = false;
  tooltipForCart: Boolean = true;
  cartIcnTooltipCnt = 0;
  isSSR: boolean = typeof window === "undefined";
  newUIflag = true;
  isDesktop: boolean;
  giftBoxCount: number = 0;
  isRedirect = true;
  private animationItem!: AnimationItem;
  public showBeautyQuizOnBoardScreen: boolean = false;
  public giftBoxHeaderConfig = {
    path: 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1658137197_lf30_editor_80wrbl3g.json',
    background: "transparent",
    speed: "1",
    loop: false
  };
  constructor(
    private router: Router,
    private storageService: StorageService,
    private injector: Injector,
    @Optional() @Inject(InjectionTokens.is_robot) is_robot: boolean,
    @Optional() @Inject(InjectionTokens.is_webview) is_webview: boolean,
    @Optional() @Inject(InjectionTokens.modeDevice) modeDevice: boolean,
  ) {
    if (this.isSSR) {
      this.isBot = String(is_robot) == 'true';
      this.isWebview = String(is_webview) == 'true';
      this.modeDevice = String(modeDevice);
      //for ssr as cookie may not able to serve data, getting data with Dependency Injection Token which already provided in server.ts
    } else {
      const isRobot = this.storageService.getCookie('is_robot');
      typeof isRobot !== 'undefined' && isRobot !== null && (this.isBot = String(isRobot) == 'true');
      this.isWebview = String(this.storageService.getCookie('is_webview')) == 'true';
      this.modeDevice = this.storageService.getCookie('mode_device');
    }
    this.isDesktop = this.modeDevice == ModeDevice.desktop;
    this.buildNumber = this.storageService.getCookie("build_number");
    this.quote = this.createRandomQuote();
    this.updateHorizontalScroll = new Subject();
    if (!this.isSSR) {
      this.scrollObservable = merge(fromEvent(window, "scroll"), this.updateHorizontalScroll);
    }
  }

  getRandomQuote(): any {
    return this.quote;
  }
  createRandomQuote(): any {
    let quotesList: any = [
      "Everyday is a fashion show and the world is the runway - Coco Chanel",
      "Happy girls are the prettiest - Audrey Hepburn",
      "Be your own kind of beautiful",
      "Never ask a girl with winged eyeliner, why she's late.",
      "Inner beauty is great, but a little mascara never hurts",
      "Be the best version of you.",
      "May the wings of your eyeliner always be even.",
      "Beauty is self-confidence, directly applied to the face",
      "Beauty begins the moment you decide to be yourself - Coco Chanel",
      "A girl should be two things, classy and fabulous - Coco Chanel",
      "Next time you think of beautiful things, don't forget to count yourself in.",
      "People will stare. Make it worth their while.",
      "Beauty is about enhancing what you have. Let yourself shine through. - Janelle Monae",
      "Make up can't solve all your problems, But its a pretty fab start.",
      "Everything has beauty, but not everyone sees it. - Confucius",
    ];
    return quotesList[Math.floor(Math.random() * quotesList.length)];
  }
  extractDomain(url) {
    var domain;
    if (url.indexOf("://") > -1) {
      domain = url.split("/")[2];
    } else {
      domain = url.split("/")[0];
    }
    domain = domain.split(":")[0];
    return domain;
  }
  internalRouteWithParams(customurl: any = "") {
    let paramsObj: any = [];
    if (customurl[1] !== undefined && customurl[1] != null && customurl[1] != "") {
      let params = customurl[1].split("&");
      for (let i = 0; i < params.length; i++) {
        let key = params[i].split("=");
        Object.assign(paramsObj, { [key[0]]: key[1] });
      }
    }
    let navigationExtras: any = {
      queryParams: paramsObj,
    };
   !this.isSSR && setTimeout(() => {
      this.router.navigate(["" + customurl[0]], navigationExtras);
    }, 20);
  }
  handleRoute(url: any, fromComponent?: any) {
    if (!navigator.onLine) { return; }
    if (url !== "#" && url !== "") {

      if (typeof url !== undefined && url != null) {
        this.storageService.firstNavigate = true;
        this.storageService.showExitPopupOnNextBack = false;
        let res: any = url;
        var newUrl = res.split(".com");
        if (res != "") {
          if (!this.isSSR && url.indexOf("://") != -1 && url.indexOf("purplle.com") == -1) {
            // external url opens in new tab-- sandeep G
            var win = window.open(url, "_blank");
            win.focus();
            return;
          } else {
            //PRS :: We will leverage window.open(url, "_self"); to load the routes
            //from another repository.
            //As we add more URLs here, we will exclude them from window.open()
            //A similar window.open shall also be part of neo-angular-ssr repository
            if (!this.isSSR) {
              let patternsNotHandledByCurrentRepo = [];
              let handledByCurrentRepo = true;
              for (let i = 0; i < patternsNotHandledByCurrentRepo.length; i++) {
                if (url.indexOf(patternsNotHandledByCurrentRepo[i]) >= 0) {
                  handledByCurrentRepo = false;
                  break;
                }
              }
              if (handledByCurrentRepo) {
                if (newUrl.length > 1) {
                  res = newUrl[1];
                }
              } else {
                this.eventHandler.next({ type: 'sendEventsInstantly', paramObj: null });
                window.open(url, "_self");
                return;
              }
            } else {
              if (newUrl.length > 1) {
                res = newUrl[1];
              }
            }
          }
        }
        if (res.charAt(0) == "/") {
          res = res.substring(1);
        }
        var arr = res.split("/");
        var catlist = [];
        var customurl = res.split("?");
        if (arr[0] == "brand") {
          let customSlug: any = "";
          if (typeof arr[1] !== undefined && arr[1] != null) {
            this.internalRouteWithParams(customurl);
          } else {
            !this.isSSR && setTimeout(() => {
              this.router.navigate(["/brand"]);
            }, 20);
          }
        } else if (!this.isSSR && arr[0] == "product") {
          if (
            typeof arr[2] !== undefined &&
            arr[2] != null &&
            (arr[2] == "writereview" || arr[2] == "askquestion" || arr[2] == "searchquestion")
          ) {
            setTimeout(() => {
              this.router.navigate([res]);
            }, 20);
          } else if (typeof arr[1] !== undefined && arr[1] != null && arr[1] == "sellers") {
            setTimeout(() => {
              this.router.navigate([res]);
            }, 20);
          } else {
            if (typeof fromComponent !== undefined && fromComponent != null && fromComponent == "product") {
              setTimeout(() => {
                this.router.navigate(["/product", decodeURIComponent(arr[1])]);
              }, 20);
            } else {
              setTimeout(() => {
                this.router.navigate([res]);
              }, 20);
            }
          }
        } else if (arr[0] == "support.purplle.com") {
          if (!this.isSSR) {
            setTimeout(() => {
              window.open("http://support.purplle.com/", "_blank");
            }, 20);
          }
        } else if (
          arr[0] == "mailto:wecare4u@purplle.com?subject=We%20would%20love%20to%20hear%20from%20you!%20-%20Purplle%20Mobile%20site"
        ) {
          if (!this.isSSR) {
            setTimeout(() => {
              window.location.href =
                "mailto:wecare4u@purplle.com?subject=We%20would%20love%20to%20hear%20from%20you!%20-%20Purplle%20Mobile%20site";
            }, 20);
          }
        } else if (!this.isSSR && arr[0] == "mb") {
          let k = arr[1];
          if (arr[1] !== undefined && arr[1].indexOf("apply_makeup") != -1) {
            let urlArr = url.split("p_id=");
            this.tryonUrl = urlArr[1];
            this.router.navigateByUrl("/tryon?p_id=" + this.tryonUrl);
          } else if (arr?.[1] == "elitepage") {
            this.router.navigate(["wv/elite"]);
          } else if(!this.isSSR){
            window.location.href = url;
          }
          setTimeout(() => { }, 20);
        } else if (arr[0] == "promo") {
          if (typeof arr[1] !== undefined && arr[1] != null) {
            this.internalRouteWithParams(customurl);
          } else if(!this.isSSR){
            setTimeout(() => {
              this.router.navigate(["/promo", decodeURIComponent(arr[1])]);
            }, 20);
          }
        } else if (!this.isSSR && arr[0] == "curated") {
          setTimeout(() => {
            this.router.navigate(["/curated", arr[1]]);
          }, 20);
        } else if (!this.isSSR && arr[0] == "bookings") {
          setTimeout(() => {
            window.location.href = url;
          }, 20);
        } else if (arr[0] == "elite") {
          this.router.navigate(["wv/elite"]);
        } else if (!this.isSSR && arr[0] == "profile") {
          if (typeof arr[1] !== undefined && arr[1] != null && arr[1] == "orders") {
            setTimeout(() => {
              this.router.navigate([res]);
            }, 20);
          } else if (typeof arr[1] !== undefined && arr[1] != null && arr[1] == "elite") {
            this.router.navigate(["wv/elite"]);
          } else if ((arr[0] == "profile" && arr[1] == "beautyprofile") || (arr[1] == "profile" && arr[2] == "beautyprofile")) {
            setTimeout(() => {
              this.router.navigate(["/profile/beautyprofile"]);
            }, 20);
          } else {
            setTimeout(() => {
              customurl ? this.internalRouteWithParams(customurl) : this.router.navigate([res]);
            }, 20);
          }
        } else if (!this.isSSR && arr[0] == "discover" && arr[1] == "beautyassistant") {
          if (arr[2] === undefined) {
            setTimeout(() => {
              this.router.navigate(["/discover/beautyassistant"]);
            }, 20);
          } else if (arr[2] !== undefined) {
            setTimeout(() => {
              this.internalRouteWithParams(customurl);
            }, 20);
          } else {
            setTimeout(() => {
              this.router.navigate([res]);
            }, 20);
          }
        } else if (arr[0] == "hashtag") {
          this.router.navigate(["/discover/hashtag", decodeURIComponent(arr[1])]);
        } else if (arr[0] == "thread") {
          this.router.navigate(["/discover/thread", decodeURIComponent(arr[1])]);
        } else if (arr[0] == "fitme" && typeof arr[1] !== undefined && arr[1] != null) {
          this.router.navigate(["/discover/fitme", decodeURIComponent(arr[1])]);
        } else if (arr[0] == "findmyfit" && typeof arr[1] !== undefined && arr[1] != null) {
          this.router.navigate(["/discover/findmyfit", decodeURIComponent(arr[1])]);
        } else if (arr[0] == "category") {
          this.internalRouteWithParams(customurl);
        } else if (!this.isSSR && arr[0] == "pr") {
          setTimeout(() => {
            this.router.navigate(["/pr", arr[1]]);
          }, 20);
        } else if (arr[0] == "n") {
          this.internalRouteWithParams(customurl);
        } else if (arr[0] == "search" || arr[0].indexOf("search") == 0) {
          this.internalRouteWithParams(customurl);
        } else if (arr[0] == "mbh" && arr[1] && arr[1].indexOf("giftbox") > -1) {
          this.internalRouteWithParams(customurl);
        } else {
          if (this.storageService.getFromStorage("categorylist")) {
            let categories: any = JSON.parse(this.storageService.getFromStorage("categorylist"));
            for (var i = 0; i < categories.length; i++) {
              catlist.push(categories[i]["url"]);
            }
          }
          if (!this.isSSR && catlist.length > 0) {
            if (arr.length == 1 && (catlist.indexOf(res) > -1 || typeof customurl[1] != "undefined")) {
              if (typeof customurl[1] == "undefined") {
                setTimeout(() => {
                  this.router.navigate(["/", arr[0]]);
                }, 20);
              } else {
                setTimeout(() => {
                  this.router.navigate(["/", res]);
                }, 20);
              }
            } else if (arr.length == 2 && (catlist.indexOf(res) > -1 || typeof customurl[1] != "undefined")) {
              if (typeof customurl[1] == "undefined") {
                setTimeout(() => {
                  this.router.navigate(["/", arr[0], arr[1]]);
                }, 20);
              } else {
                setTimeout(() => {
                  this.router.navigate(["/", res]);
                }, 20);
              }
            } else if (arr.length == 3 && (catlist.indexOf(res) > -1 || typeof customurl[1] != "undefined")) {
              if (typeof customurl[1] == "undefined") {
                setTimeout(() => {
                  this.router.navigate(["/", arr[0], arr[1], arr[2]]);
                }, 20);
              } else {
                setTimeout(() => {
                  this.router.navigate(["/", res]);
                }, 20);
              }
            } else {
              setTimeout(() => {
                this.router.navigate([res]);
              }, 20);
            }
          } else if (!this.isSSR){
            if (res.indexOf("?") >= 0) {
              setTimeout(() => {
                this.router.navigateByUrl(res);
              }, 20);
            } else {
              setTimeout(() => {
                this.router.navigate([res]);
              }, 20);
            }
          }
        }
      }

    }
  }

  getDeviceRegistrationEvent() {
    return this.deviceRegistrationEvent.asObservable();
  }
  getEventHandler(){
    return this.eventHandler.asObservable();
  }
  getViewAllConfig() {
    return this.viewAllConfig.asObservable();
  }
  setViewAllConfig(value, fromHome?: boolean) {
    if (fromHome) {
      this.viewAllObj = value;
    } else {
      this.viewAllConfig.next(value);
    }
  }
  getShowToast() {
    return this.showToastSub.asObservable();
  }
  setShowToast(value) {
    this.showToastSub.next(value);
  }
  getPopupSubscription(){
    return this.popupSubscription.asObservable();
  }
  openPopup(config: AppPopupConfigModel){
    this.popupSubscription.next(config);
  }
  getCartCount(): any {
    if (this.storageService.getFromStorage("nc") != null) {
      let nc: any;
      nc = JSON.parse(this.storageService.getFromStorage("nc"));
      if (nc["cc"] != null) {
        this.cartcount = parseInt(nc["cc"]);
        return this.cartcount;
      } else {
        this.cartcount = 0;
        return this.cartcount;
      }
    } else {
      this.cartcount = 0;
      return this.cartcount;
    }
  }
  getupdateCartCount(): any {
    return this.cartCountSubject.asObservable();
  }
  updateCartCount(newCount: any) {
    this.cartcount = newCount || 0;
    let nc: any;
    if (this.storageService.getFromStorage("nc") != null) {
      nc = JSON.parse(this.storageService.getFromStorage("nc"));
    } else {
      nc = {};
    }
    nc["cc"] = this.cartcount;
    this.storageService.setInStorage("nc", JSON.stringify(nc));
    this.cartCountSubject.next(this.cartcount);
  }
  updateWishlistCount(newCount: any) {
    this.wishlistcount = newCount;
    this.wishlistSubscription.next(this.wishlistcount);
  }
  showToastMsg(msg: any) {
    if (!this.isSSR && msg != null) {
      this.closeToast();
      this.showToast = true;
      this.toastMsg = msg;
      this.setShowToast(true);
      this.timeoutTimer && clearTimeout(this.timeoutTimer);
      this.timeoutTimer = setTimeout(() => {
        this.closeToast();
      }, 3000);
    }
  }
  closeToast() {
    this.showToast = false;
    this.toastMsg = "";
    this.setShowToast(false);
  }
  postOnlineHandling() {
    const postOnlineRedirection: any = { urlStr: this.router.url || "" };
    this.storageService.setInStorage("postOnlineRedirection", JSON.stringify(postOnlineRedirection));
    this.router.navigateByUrl("/offline", { skipLocationChange: true });
  }
  scrollFunction(eid: any, scrollTarget?: any, removeHdrHeight?: any) {
    if(this.isSSR){ return;}
    var target: number;
    if (typeof eid !== undefined && eid != null && eid != "") {
      if(this.isDesktop){
        target = removeHdrHeight == true ? this.elmYPosition(eid) - 160 : this.elmYPosition(eid);
      }
      else{
        target = removeHdrHeight == true ? this.elmYPosition(eid) - 50 : this.elmYPosition(eid);
      }
    } else {
      target = scrollTarget;
    }
    var stopY = target;
    var startY = window.pageYOffset;
    var distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
      window.scrollTo(0, stopY);
      return;
    }
    var speed = Math.round(distance / 100);
    if (speed >= 20) speed = 50;
    var step = Math.round(distance / 25);
    var leapY = stopY > startY ? startY + step : startY - step;
    var timer = 0;
    if (stopY > startY) {
      for (var i = startY; i < stopY; i += step) {
        this.scrollTo(leapY, timer * speed);
        leapY += step;
        if (leapY > stopY) leapY = stopY;
        timer++;
      }
      return;
    }
    for (var i = startY; i > stopY; i -= step) {
      this.scrollTo(leapY, timer * speed);
      leapY -= step;
      if (leapY < stopY) leapY = stopY;
      timer++;
    }
  }
  elmYPosition(eID) {
    var elm = document.getElementById(eID);
    if (elm !== undefined && elm != null) {
      var y = elm.offsetTop;
      let node: any = elm;
      while (node.offsetParent && node.offsetParent != document.body) {
        node = node.offsetParent;
        y += node.offsetTop;
      }
      return y;
    }
    return 0;
  }
  scrollTo(yPoint: number, duration: number) {
    !this.isSSR && setTimeout(() => {
      window.scrollTo(0, yPoint);
    }, duration);
    return;
  }
  handleRoutesEventManager(event, url: any, type?: any, fromComponent?: any) {
    const patternsToHandleWithDefault = ["mailto:","tel:"];
    if(!navigator.onLine || this.isSSR){ return;}
    if (typeof url == 'string' && patternsToHandleWithDefault.some(pattern => url?.startsWith(pattern))) { return; }
    if (typeof event.preventDefault == "function") event.preventDefault();
    if (event.ctrlKey || event.metaKey || (event.button && event.button == 1)) {
      //opening in new tab
      var win = window.open(url, "_blank");
      win.focus();
    } else if (event.shiftKey) {
      //opening in new window
      window.open(url, "_blank");
    } else {
      //normal left click on mouse
      //letting handleRoutes handle it and preventing the default functionality of the browser
      if (type != "old") {
        if (typeof event.preventDefault == "function") event.preventDefault();
        if (url !== "#" && url !== "") {
          if (typeof url !== "undefined" && url != null) {
            this.handleRoute(url, fromComponent);
          }
        }
      } else {
        if (url !== "#" && url !== "") {
          if (typeof url !== "undefined" && url != null) {
            window.location.href = url;
          }
        }
      }
    }
    return;
  }
  elementInViewport(el) {
    if (el == undefined || el == null || this.isSSR) return false;
    let rect = el.getBoundingClientRect();
    let html = document.documentElement;
    return (
      // top condition
      (rect.bottom >= -500 &&
        rect.top <= (window.innerHeight || html.clientHeight) &&
        rect.left >= -500 &&
        rect.right <= (window.innerWidth || html.clientWidth) + 500) ||
      //bottom condition
      (rect.top <= (window.innerHeight || html.clientHeight) + 300 &&
        rect.left >= -300 &&
        rect.right <= (window.innerWidth || html.clientWidth) + 300 &&
        rect.bottom >= 0) ||
      // left condition
      (rect.left <= (window.innerWidth || html.clientWidth) &&
        rect.top >= -300 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) + 300 &&
        rect.right >= -300) ||
      //right condition
      (rect.left <= (window.innerWidth || html.clientWidth) + 300 &&
        rect.top >= -300 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) + 300 &&
        rect.right >= 0)
    );
  }
  updateGiftBoxCartCount(giftboxCount?:number) {
    let giftBox: any;
    this.giftBoxCount = giftboxCount;
    if (this.storageService.getFromStorage("giftBox") != null) {
      giftBox = JSON.parse(this.storageService.getFromStorage("giftBox"));
    } else {
      giftBox = {};
    }
    giftBox["itemCount"] = this.giftBoxCount;
    this.storageService.setInStorage("giftBox", JSON.stringify(giftBox));
    this.giftBoxCountSubject.next(this.giftBoxCount);
  }
  getGiftBoxCartCount(): any {
    if (this.storageService.getFromStorage("giftBox") != null) {
      let giftBox: any;
      giftBox = JSON.parse(this.storageService.getFromStorage("giftBox"));
      if (giftBox["itemCount"] != null) {
        this.giftBoxCount = parseInt(giftBox["itemCount"]);
        return this.giftBoxCount;
      } else {
        this.giftBoxCount = 0;
        return this.giftBoxCount;
      }
    } else {
      this.giftBoxCount = 0;
      return this.giftBoxCount;
    }
  }
  getUpdateGiftBoxCartCount(): any {
    return this.giftBoxCountSubject.asObservable();
  }
  beautyBoxAnimationCreated(animationItem: AnimationItem) {
    this.animationItem = animationItem;
    if(this.storageService.getFromSessionStorage("giftBoxAnimation")) {
      this.animationItem.autoplay = false;
    } else {
      this.storageService.setInSessionStorage("giftBoxAnimation", "true");
      this.animationItem.autoplay = true;
    }
  }
}
