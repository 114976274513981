import { HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { RequestService } from "./request.service";
@Injectable({providedIn:"root"})
export class StorageService {
  isStorageSupported: boolean = true;
  isSSR: boolean = typeof window === "undefined";
  excludeFromCookiefyForSSR: any = [
    "logoData",
    "newmenusExpiry",
    "props",
    "newmenus",
    "pages_home_cachedResp_m",
    "cartList",
    "wishlist",
    "ud",
    "props",
    "pages_home_cachedExpiry_m",
    "user",
    "topBrands",
    "brands",
    "brandsMeta",
    "searchHistory",
    "branch_session_first",
    "BRANCH_WEBSDK_KEYjourneyDismissals",
    "searchinitResp"
  ];
  excludeKeyContainingFromCookiefyForSSR: any = ["_cachedResp_", "_cachedExpiry_"];
  requestHeaders: any = undefined;
  environment: any;
  showExitPopup: boolean = false;
  firstNavigate: boolean;
  showExitPopupOnNextBack: boolean;
  web_token = null;

  constructor(@Optional() @Inject(RequestService) requestHeaders: any) {
    this.isStorageSupported = this.checkStorageSupported();
    if (this.isSSR && requestHeaders) this.requestHeaders = requestHeaders?.request?.headers;
    this['ENVIRONMENT'] = this.getCookie("environment");
    if (!this.isSSR) this.removeExcludedCookiefyForSSR();
  }

  //31-Mar-2021 PRS : This is a temporary method to remove storage keys that are cookiefied
  //via prior deployment. This method should be removed in about 2 weeks time.
  //It just ensures users who have older cookies not needed have their cookie removed.
  removeExcludedCookiefyForSSR() {
    for (let k = 0; k < this.excludeFromCookiefyForSSR.length; k++) {
      this.clearCookie("__storage__" + this.excludeFromCookiefyForSSR[k]);
    }
  }
  excludeFromSSRCookie(key) {
    if (this.excludeFromCookiefyForSSR.indexOf(key) >= 0) return true;
    for (let k = 0; k < this.excludeKeyContainingFromCookiefyForSSR.length; k++) {
      if (key.indexOf(this.excludeKeyContainingFromCookiefyForSSR[k]) >= 0) return true;
    }
    return false;
  }

  getFromRequestHeaderOnSSR(k: string) {
    if (this.requestHeaders) {
      if (k in this.requestHeaders) return this.requestHeaders[k];
      else return undefined;
    }
  }
  setInSessionStorage(k: string, v: string) {
    if (!this.isSSR) {
      window.sessionStorage.setItem(k, v);
      if (!this.excludeFromSSRCookie(k)) this.setCookie("__sessionstorage__" + k, this.getSSREncoded(v));
    }
  }
  getFromSessionStorageSSR(k: string) {
    return this.getCookieSSR("__sessionstorage__" + k);
  }

  getFromSessionStorage(k: string) {
    if (this.isSSR) return this.getFromSessionStorageSSR(k);
    else return window.sessionStorage.getItem(k);
  }

  removeFromSessionStorage(k: string) {
    if (!this.isSSR) {
      window.sessionStorage.removeItem(k);
      this.clearCookie("__sessionstorage__" + k);
    }
  }
  checkStorageSupported() {
    if (this.isSSR) return false;
    var testKey = "_storage_test",
      storage = window.sessionStorage;
    try {
      storage.setItem(testKey, "1");
      storage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }

  getFromStorageSSR(k: any) {
    return this.getCookieSSR("__storage__" + k);
  }

  getFromStorage(k: any) {
    if (this.isSSR) return this.getFromStorageSSR(k);
    else {
      if (this.isStorageSupported == true) {
        if (window.localStorage.getItem(k) === undefined) {
          return null;
        } else {
          return window.localStorage.getItem(k);
        }
      } else {
        if (typeof window["local"][k] !== undefined && window["local"][k] != null && window["local"][k] != undefined) {
          return window["local"][k];
        } else {
          return null;
        }
      }
    }
  }
  setItemsToCartList(items) {
    if (this.isSSR) return;
    let cartList = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].product_id) cartList.push(items[i].product_id);
      }
      this.setInStorage("cartList", JSON.stringify(cartList));
    } else {
      this.unsetFromStorage("cartList");
    }
  }
  setCartItemsToCartList(items) {
    if (this.isSSR) return;
    let cartList = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].itemId) cartList.push(items[i].itemId);
      }
      this.setInStorage("cartList", JSON.stringify(cartList));
    } else {
      this.unsetFromStorage("cartList");
    }
  }
  setItemsToWishList(items) {
    if (this.isSSR) return;
    let wishlist = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        wishlist.push(items[i].product_id);
      }
      this.setInStorage("wishlist", JSON.stringify(wishlist));
    }
  }
  setInStorage(k: any, v: any) {
    if (this.isSSR) return;
    if (this.isStorageSupported == true) {
      window.localStorage.setItem(k, v);
      if (!this.excludeFromSSRCookie(k)) this.setCookie("__storage__" + k, this.getSSREncoded(v));
    } else {
      setTimeout(() => {
        window["local"][k] = v;
        if (!this.excludeFromSSRCookie(k)) this.setCookie("__storage__" + k, this.getSSREncoded(v));
      }, 1);
    }
  }
  unsetFromStorage(k: any) {
    if (this.isSSR) return;
    if (this.isStorageSupported == true) {
      window.localStorage.removeItem(k);
      this.clearCookie("__storage__" + k);
    } else {
      setTimeout(() => {
        delete window["local"][k];
        this.clearCookie("__storage__" + k);
      }, 1);
    }
  }

  //PRS : For storage + sessionstorage items stored as cookies (so that they are available  during SSR)
  //we need to handle "=" and ";" within cookie values
  //we achieve this by HTML encoding and decoding "=" to %3D as well as ; as %3B
  //A limitation of this approach is that actual %3D and %3B within storage values may result
  //in unexpected behavior.
  getSSREncoded(val: string) {
    if (typeof val === "string" && (val.indexOf("=") >= 0 || val.indexOf(";") >= 0))
      return val.split("=").join("%3D").split(";").join("%3B");
    else return val;
  }
  getSSRDecoded(val: string) {
    if (typeof val === "string" && (val.indexOf("%3D") >= 0 || val.indexOf("%3B") >= 0))
      return val.split("%3D").join("=").split("%3B").join(";");
    else return val;
  }

  getCookieSSR(k): any {
    if (this.isSSR && this.requestHeaders) {
      let cookieVal = undefined;
      if ("cookie" in this.requestHeaders) {
        let rc = this.requestHeaders["cookie"];
        rc &&
          rc.split(";").forEach(function (cookie) {
            let parts = cookie.split("=");
            if (parts && parts[0].trim() == k.trim()) cookieVal = decodeURI(parts[1].trim());
          });
        return this.getSSRDecoded(cookieVal);
      }
    } else return undefined;
  }

  getCookie(name: string) {
    if(name=='token' && this.web_token){ return this.web_token}
    name = this.getEnvCookieName(name);
    if (this.isSSR) return this.getCookieSSR(name);
    else {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length >= 2) {
        const finalVal = parts.pop()?.split(";")?.shift();
        (name=='token') && (this.web_token = finalVal)
        return finalVal;
      }
    }
  }
  getEnvCookieName(name: string) {
    if (name == "token") {
      if (
        this['ENVIRONMENT'] &&
        this['ENVIRONMENT'] != "" &&
        this['ENVIRONMENT'] != "undefined" &&
        this['ENVIRONMENT'] != null &&
        this['ENVIRONMENT'] != "prod"
      ) {
        return this['ENVIRONMENT'] + "_" + name;
      }
    }
    return name;
  }
  setCookie(name, value, days?: any) {
    if(name == 'token'){ this.web_token = value; }
    name = this.getEnvCookieName(name);
    if (this.isSSR) return;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date;
    } else {
      var expires = "";
    }
    document.cookie = name + "=" + value + expires + ";domain=.purplle.com;path=/;";
  }

  setCookieExpiryInMins(name, value, mins?: number) {
    if (this.isSSR) return;
    if (mins) {
      var date = new Date();
      date.setTime(date.getTime() + mins * 60 * 1000);
      date.setTime(date.getTime());

      var expires = "; expires=" + date;
    } else {
      var expires = "";
    }
    document.cookie = name + "=" + value + expires + ";domain=.purplle.com" + ";path=/;";
  }

  clearCookie(name) {
    if (this.isSSR) return;
    if (name) {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.purplle.com" + ";path=/;";
    }
  }

  //get http request headers for httpService (Code written here to avoid cyclic dependency of commonService and httpService). This method is referenced in more than one places, each having its own error handling.

  getGenericRequestHeaders() {
    const token =  this.getCookie("generic_token");
    const visitorppl = this.getCookie("generic_visitorppl");
    let headers = new HttpHeaders();
    let cookieVal = "";
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    if (token) {
      headers = headers.set("token", token);
      cookieVal += "token=" + token + ";";
    }

    if (visitorppl) {
      headers = headers.set("visitorppl", visitorppl);
      cookieVal += "visitorppl=" + visitorppl + ";";
    }
    headers = headers.set("cookie", cookieVal);
    return headers;
  }

  getRequestHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    const isRobot = this.getCookie("is_robot");
    const mode_device = this.getCookie("mode_device");
    const token = isRobot == 'false' ? this.getCookie("token") : this.getCookie("generic_token");
    const visitorppl = isRobot == 'false' ? this.getCookie("visitorppl") : this.getCookie("generic_visitorppl");
    const app_devicetype = this.getCookie("app_devicetype");
    const app_type = this.getCookie("app_type");
    const app_token = this.getCookie("app_token");
    const test_app_device = this.getCookie("test_app_device");

    const web_deviceType = ["mobile", "desktop"]
    if ( (app_devicetype || app_type) && (!web_deviceType.includes(app_devicetype) || !web_deviceType.includes(app_type)) ) {

      if (app_token || token) headers = headers.set("token", app_token || token);
      if (test_app_device || visitorppl) headers = headers.set("device_id", test_app_device || visitorppl);

      if (app_type) headers = headers.set("type", app_type);
      if (app_devicetype) headers = headers.set("devicetype", app_devicetype);

    } else {
      if (token) headers = headers.set("token", token);
      if (visitorppl) headers = headers.set("visitorppl", visitorppl);
    }
    if (mode_device) headers = headers.set("mode_device", mode_device);
    return headers;
  }
  setItemsToGiftBoxCartList(items) {
    if (this.isSSR) return;
    let giftBoxList = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].product_id) giftBoxList.push(items[i].product_id);
      }
      this.setInStorage("giftBoxCart", JSON.stringify(giftBoxList));
    } else {
      this.unsetFromStorage("giftBoxCart");
    }
  }
  getLoggedInUserId(): string {
    const isUserLoggedIn = this.getFromStorage('isLoggedIn');
    if (isUserLoggedIn) {
      if (this.getFromStorage("ud") != null) {
        let ud: any = JSON.parse(this.getFromStorage("ud"));
        return ud["uid"];
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
}
