
export enum AndroidEvents {
  actionLogIn = "log_in",
  actionOpenDeeplink = "open_deeplink",
  actionShare = "share",
  actionClose = "close",
  channelAll = "all",
  channelWhatsapp = "whatsapp",
  channelGmail = "gmail",
  hideLoader = "hide_center_progress_bar",
  addToCart = "add_to_cart",
  addToWishlist = "add_to_wishlist",
  deleteFromCart = "delete_cart_item",
  addToBeautyBox = "add_to_beautybox",
  deleteFromBeautyBox = "delete_from_beautybox",
  callInitstate = "call_initstate",
}

export enum ModeDevice {
  mobile = "mobile",
  android = "android",
  ios = "ios",
  desktop = "desktop",
  MobileEvent = "Mobile",
  AndroidEvent = "Android",
  iOSEvent = "iOS",
  DesktopEvent = "Desktop",
}

export enum CustomerGluEvents {
  eventOpenDeeplink = "OPEN_DEEPLINK",
  eventShare = "SHARE",
  eventClose = "CLOSE",
  channelWhatsapp = "WHATSAPP",
}

export const InjectionTokens = {
  isServerSideLoggedIn : "is_server_side_logged_in",
  modeDevice : "mode_device",
  is_webview : 'is_webview',
  is_robot : "is_robot",
}

export enum AndroidLinks {
  homePage = "purplle.com://open",
  cartPage = "purplle.com://cart",
  productPage = "purplle.com://product?type_id=",
  ticketHistoryPage = "purplle.com://ticketHistory",
  viewTicketDeepLink = "purplle.com://ticketDetail?url=/poems/api/v1/freshdesk/ticket/get?id=",
  ticketDetailsIndex = "&&is_ticket_detail=1",
}

export enum ProductVariantGroupDisplayType {
  image = "image",
  colorCode = "color_code",
}

export enum SwiperSlideType {
  videoVimeo = "video_vimeo",
  image = "image"
}

export enum WishlistActions {
  add = "add",
  remove = "remove",
}

export enum ViewAllConfigType {
  viewAll = "viewall",
  menu = "menu",
}

export enum FilterActionTypes {
  moreFilter = "more_filter",
  sortBy = "sort_by",
  clearFilter = "clearFilter",
}

export enum FilterTypes {
  categoryFilter = "cf",
  brandFilter = "bf",
  attributeFilter = "af",
  priceRangeFilter = "pf",
  discountFilter = "df",
  extraFilter = "ef",
  chipFilter = "chipFilter"
}

export enum FilterNames {
  categories = "Categories",
  brands = "Brands",
  priceRange = "Price Range",
  discounts = "Discounts",
}

export enum SortByTypes {
  relevance = "Relevance",
  discount = "Discount",
  highPrice = "High Price",
  lowPrice = "Low Price",
  averageRating = "Average Rating",
}

export enum SearchTypes {
  suggestions = "suggestions",
  searchHistory = "searchhistory",
  main = "main",
  brand = "brand",
  question = "question",
}

export enum SearchSuggestionTypes {
  search = "Search",
  product = "Product",
}

export enum HeaderMenuTypes {
  mainMenu = "mainmenu",
  subMenu = "submenu",
  brandTabMenu = "brandtabmenu",
}

export enum HeaderMenuItems {
  home = "home",
  brands = "brands",
  luxury = "luxury",
  new = "new",
  magazine = "magazine",
  shop = "shop",
  offers = "offers",
  splurge = "splurge",
  skin = "skin",
  mostViewed = "mostviewed",
  featured = "featured",
  beauty = "beauty",
  mainSearch = "main-search",
  shopMenu = "shop-menu",
}

export enum HeaderSectionConstants {
  default = 'default',
  panel = 'panel',
  appDownloadCurrentPage = 'app-download',
  brandsListCurrentPage = 'brands-list',
  offersCurrentPage = 'offers',
  eliteCurrentPage = 'elite',
  menuCurrentPage = 'menu',
  mainSearchCurrentPage = 'main-search',
  aboutUsCurrentPage = 'about-us',
  viewall = 'viewall',
  discoverCurrentPage = 'discover',
  magazineCurrentPage = 'magazines',
  favoritesCurrentPage = 'favourites',
  customerServiceCurrentPage = 'customer-service',
  brandsDetailCurrentPage = 'brand-details',
  writeReviewCurrPage = 'writereview',
  reviewsCurrentPage = 'reviews',
  beautyProfileCurrPage = 'beauty-profile',
  cancelOrderCurrPage = 'Cancel order',
  followListCurrPage = 'followslist',
  profileReviewCurrPage = 'profile-reviews',
  ordersCurrPage = 'orders',
  cartCurrPage = 'cart',
  errorCurrPage = 'error',
  offlineCurrPage = 'offline',
  tryOnCurrentPage = 'virtual-try-on',
  paymentsPage = 'payments',
  returnOrderPage = 'returnorder',
  addNewAddressTitle = 'ADD NEW ADDRESS',
  editAddressTitle = 'EDIT ADDRESS',
  editProfileTitle = 'Edit Profile',
  myAddressTitle = 'My Address',
  deleteAccountTitle = "Delete Account",
  returnItemTitle = "Return Item",
  changePasswordTitle = "Change Password",
  trackShipmentTitle = "Track Shipment",
  addMoreImagesTitle = "Add More Images",
  purplleCreditTermsAndConditionsTitle = "Purplle Credits Terms and Conditions",
  lp = "lp",
  offers = "offers",
  home = "home",
  pageNotFound = "pageNotFound",
  listing = "listing",
  customerServiceTitle = 'Customer Service',
  createTicket = "Create Ticket",
  blogDetails = "blog-details",
  checkoutPage = 'checkout',
  support = "support",
  addressListPage = 'CHANGE ADDRESS',
  payment = 'Payment',
  productReviews = "Product Reviews",
  productDetails = 'product-details',
  categories = "Categories",
  myCart = "My Cart",
  beautystudio = "beautystudio",
  profile = "profile",
  myOrders = "My Orders",
  orderDetails = "Order Details",
  trackOrder = "Track Order",
  beautyQuiz = "Beauty Quiz",
  beautyProfile = "Beauty Profile",
  purplleCredit = "Purplle Credit",
  purplleCreditDetails = "Purplle Credit Details",
  brandSearch = "brand-search",
  address = "ADDRESS",
}

export enum HeaderConfig {
  headerSection = "headerSection",
  currentPage = "currentPage",
  overlayVisible = "OverlayVisible",
  menuDrawerVisible = "MenuDrawerVisible",
  headerFixed = "headerFixed",
  userLoggedIn = "UserLoggedIn",
  showBackBtn = "showBackBtn",
  showCloseBtn = "showCloseBtn",
  goBackToURL = "goBackTOURL",
  showMenuDrawer = "showMenuDrawer",
  showLogo = "showLogo",
  showSideMenu = "showSideMenu",
  sideMenuOptions = "sideMenuOptions",
  isSideMenuVisible = "isSideMenuVisible",
  showBackConfirmPopFunc = "showBackConfirmPopFunc",
  showReloadConfirmPopFunc = "showReloadConfirmPopFunc",
  showTitle = "showTitle",
  headerTitle = "headerTitle",
  searchTerm = "searchTerm",
  searchResults = "searchResults",
  showCartBtn = "showCartBtn",
  showProfileBtn = "showProfileBtn",
  showMyAccountBtn = "showMyAccountBtn",
  showOffersBtn = "showOffersBtn",
  showShareBtn = "showShareBtn",
  showLikeBtn = "showLikeBtn",
  isOfferPage = "isOfferPage",
  isCartPage = "isCartPage",
  showHeaderOffer = "showHeaderOffer",
  showHeaderOfferBar = "showHeaderOfferBar",
  headerMenuShow = "headerMenuShow",
  showClearSearchBtn = "showClearSearchBtn",
  showSearchBtn = "showSearchBtn",
  showTryOn = "showTryOn",
  tryOnUrl = "tryOnUrl",
  productData = "productData",
  showWishlistBtn = "showWishlistBtn",
  pageLoaded = "pageLoaded",
  showTryElite = "showTryElite",
  showGiftCartBtn = "showGiftCartBtn",
  showCartCountTooltip = "showCartCountTooltip",
  customBackButtonHandle = "customBackButtonHandle",
  isDefaultHeader = "isDefaultHeader",
  isShowRightSideNav = "isShowRightSideNav",
  isShowHeader = "isShowHeader",
  searchBrandTerm = "searchBrandTerm",
  desktopFixedHeader = "desktopFixedHeader",
  showDesktopFirstSection = "showDesktopFirstSection",
  showDesktopFirstSectionOnScroll = "showDesktopFirstSectionOnScroll",
  searchImpressions = "searchImpressions",
  showHideSearch = "showHideSearch",
  showDesktopTopBanner = "showDesktopTopBanner"
}

export enum FooterSectionConstants {
  shop = "shop",
  home = "home",
  footerSection = "footerSection",
  CurrentPage = "CurrentPage",
  footerFixed = "footerFixed",
  escapeFooter = "escape-footer",
  none = "none",
  full = "full",
  showFooter = "showFooter",
  pdPageFlag = "pdPageFlag",
  brandDetails = "brand-details",
  listType = "listType",
  stickToBottom = "stickToBottom",
  showFooterGap = "showFooterGap"
}

export enum CommonConstants {
  open = "open",
  showTryElite = "showTryElite",
  oneTap = "oneTap",
  login = "login",
  back = "back",
  email = "email",
  pages = "pages",
  bspage = "bspage",
  suggestions = "suggestions",
  true = "true",
  undefined = "undefined",
  remove = "remove",
  add = "add",
  home = "Home",
  product = "product",
  story = "story",
  downloadApp = "Download App",
  goToApp = "Go to App",
  updateApp = "Update App",
  viewTicket = "View Ticket",

}

export enum PageTitle {
  faqs = "FAQs",
  purplleCredit = "purplle-credit",
  creditDetails = "Credit Details",
  profile = "profile",
  myAccount = "My Account",
  myOrders = "My Orders",
  orderDetails = "Order Details",
  trackOrder = "Track Order",
  myAddresses = "My Addresses",
  addAddress = "My Addresses",
  addNewAddress = 'Add New Address',
  editAddress = 'Edit Address',
  editProfile = 'Edit Profile',
  myWishlist = "My Wishlist",
  myReviews = "My Reviews",
  payments = "Payments",
  elite = "elite",
  deleteAccount = "Delete Account",
  priceReveal = "price-reveal",
  giftBox = "Giftbox",
  appDownload = "App Download",
  createTicket = "Create Ticket",
  blogDetails = "blog-details",
  customerService = 'Customer Service',
  freebies = 'Pick Freebies',
  trackShipmentTitle = "Track Shipment",
  returnItemTitle = "Return Item",
  settings = "Settings",
  cancelOrder = "Cancel Order",
  selectAddress = "Select Address",
  purplleCreditTermsAndConditionsTitle = "Purplle Credits Terms and Conditions",
  myCart = "MY CART",
  address = "ADDRESS",
  payment = "Payment",
  addNewCard = "ADD NEW CARD",
  filterBy = "Filter By",
  categories = "Categories",
  challenges = "Challenges",
  applyCoupon = "Apply Coupon",
  deliveryFeedback = "Delivery Feedback"
}

export enum AppDownloadLinks {
  googlePlayStorePurplleLink = "https://play.google.com/store/apps/details?id=com.manash.purplle",
  appStorePurplleLink = "https://apps.apple.com/in/app/purplle-beauty-friend-forever/id1078972948",
  androidAndIosAppUpdateDeeplink = "purplle.com://update",
  appDownloadLink = "https://dl.purplle.com/xLJmQCwBwN"
}

export enum LoginTypes {
  email = "email",
  number = "number",
  socialSuggest = "socialSuggest",
  loginPassword = "loginPassword",
  verifyPassword = "verifyPassword",
  resetPWD = "resetPWD",
  registerForm = "registerForm",
  phone = "phone"
}

export enum LoginActions {
  login = "login",
  merge = "merge",
  phoneAdd = "phone_add",
  accountSwitch = "accountSwitch",
  truecallerRegistration = "truecaller_registration"
}

export enum PopupTypes {
  auth = "auth"
}

export enum ProfileUpdateAction {
  addPhoto = "add"
}

export enum BeautyQuizUrlForSkinAnalyser {
  webUrlForSkinAnalyser = 'https://www.purplle.com/wv/beautyquiz/62145676519057c4eb61cd51',
  deeplinkForSkinAnalyser = 'purplle.com://promotion?url=https://www.purplle.com/wv/beautyquiz/62145676519057c4eb61cd51'
}

export enum NotifyMePopup {
  title = 'Notify me',
  description = 'You will be notified when it’s back in stock!',
  emailPlaceholder = 'Enter email address*',
  buttonText = 'Notify me',
  mobilePlaceholder = 'Enter Mobile number (Optional)'
}

export enum PriceRangeSlider{
  title = 'Select Price Range',
  countText = 'Products found'
}

export enum SearchDebounceConfig {
  debounceTime = 400,
}

export enum WidgetViewTypes{
  product = "product",
  smartFilters = "smart_filters"
}

export enum CartActions {
  applyCoupon = "apply-coupon",
  removeCoupon = "remove-coupon",
  addToCart = "add-to-cart",
  addToWishlist = "add-to-wishlist",
  goToCart = "Go to Cart",
  addingToCart = "Adding to Cart...",
}
export enum CartTemplateElement {
  showRecoPopup = "showRecoPopup",
  showWishlistPopup = "showWishlistPopup",
  showSamplesPopup = "showSamplesPopup"
}
export enum ApiLoadType {
  sync = 'sync',
  async = 'async',
}
export enum ApiPayload {
  orderFeedback = 'order_feedback',
  order = 'order',
}
export enum ActionType {
  add = 'add',
  edit = 'edit',
  track = 'track',
  return = 'return',
  cancel = 'cancel'
}