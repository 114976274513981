import { Inject, Injectable, Optional } from "@angular/core";
import { Request } from "express";
import { REQUEST } from "src/express.tokens";

@Injectable({ providedIn: 'root' })
export class RequestService {
  private href: string;
  private originalUrl: string;
  private isSSR = typeof window === "undefined";
  constructor( @Optional() @Inject(REQUEST) public request: Request) {
    this.calculateConfig();
  }
  private calculateConfig(){
    if (this.isSSR) {
      this.href = this.request?.protocol + "://" + this.request?.get("host") + this.request?.originalUrl;
      this.originalUrl = this.request?.originalUrl;
    } else {
      this.href = location.href;
      const originalUrl = this.href?.split(location.origin)?.[1];
      this.originalUrl = originalUrl || '/'
    }
  }
  getOriginalUrl(){
    this.calculateConfig();
    return this.originalUrl;
  }
  
}
